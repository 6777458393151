<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="85px">
			<!-- <el-form-item class="el_form_item" label="主用户手机">
				<el-input class="el_input" v-model="form.main_user_tel" placeholder="主账号手机号码" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="主用户姓名">
				<el-input class="el_input" v-model="form.main_user_name" placeholder="主账号姓名" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="主用户公司" style="width: 400px;">
				<el-input class="el_input" v-model="form.main_user_company_name" placeholder="主账号公司名称" clearable></el-input>
			</el-form-item> -->
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="open_add_view">添加</el-button>
				<!-- <el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<!-- <el-table-column label="添加时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="角色id">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="编号">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="角色名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="107px">
					<template slot-scope="scope">
						<el-button class="btn_left" style="margin-right: 10px;" @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button class="btn_left" @click="del(scope.row.id)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
		<!-- 用户数据修改弹窗 -->
		<el-dialog top="3vh" title="修改用户组" width="60%" :visible.sync="edit_para.is_show">
			<el-form status-icon label-position="left" label-width="90px">
				<el-form-item label="用户组名称" >
					<el-input class="el_input" v-model="edit_para.form.name" ></el-input>
				</el-form-item>
				<el-form-item label="用户组权限" >
					<div class="menu_list">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							<el-checkbox-group class="children_list" v-model="edit_para.form.menu">
								<el-checkbox v-for="(children_item,index) in main_item.children" :key="index" :label="children_item.id">{{children_item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="edit_view_sub">提交</el-button>
			</div>
		</el-dialog>
		<!-- 添加页面 -->
		<el-dialog top="3vh" title="角色添加" width="700px" :visible.sync="sub_user_role_add.is_show">
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="角色姓名" >
					<el-input class="el_input" v-model="sub_user_role_add.name"></el-input>
				</el-form-item>
				<el-form-item label="角色权限" >
					<div class="menu_list">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							
							<el-checkbox-group class="children_list" v-model="add_para.form.menu">
								<el-checkbox v-for="(children_item,index) in main_item.children" :key="index" :label="children_item.id">{{children_item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="sub_role_add">提交</el-button>
			
			</div>
			
	       </el-dialog>
	</div>
</template>

<script>
	import {mapMutations} from 'vuex'
	export default {
		data() {
			return {
				//菜单列表
				menu_list:{},
				//角色添加
				sub_user_role_add:{
					is_show:false,
					name:'',
				},
				//搜索条件
				form: {
					main_user_tel:'',//主用户手机
					main_user_name:'',//主用户姓名
					main_user_company_name:'',//主账号公司名称
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				//用户添加弹窗
				add_para:{
					form:{
						name:'',
						menu:[],
					},
					is_show:false,
				},
				//用户修改弹窗
				edit_para:{
					form:{},
					is_show:false,
				},
			}
		},
		created() {
			
			//读取数据
			this.get_page_data()
		},
		methods: {
			
			...mapMutations(['save_main_user_info']),
			//删除用户组
			del(ug_id){

				//询问
				this.$my.other.confirm({
					content:"是否删除此用户组",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'sub_user_role_del',
								id:ug_id
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},
			//提交修改
			edit_view_sub(){
				let menu = ''
				this.edit_para.form.menu.forEach(item => {
					menu +=item+','
				});
				menu=menu.slice(0,-1)
				//提交
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'sub_user_role_edit',
						id:this.edit_para.form.id,
						name:this.edit_para.form.name,
						menu
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.edit_para.is_show=false;
						this.get_page_data()
					},
				});
			},
			//打开用户组修改页面
			open_edit_view(item){
				this.role_add()
				let menu = ''
				if(Array.isArray(item.menu)){
					
				}else{
					menu = item.menu.split(',')
					item.menu = menu
				}
			   
				
				
				this.edit_para.form=item
				this.edit_para.is_show=true
			},
			sub_role_add(){
				let menu = ''
				this.add_para.form.menu.forEach(item => {
					menu +=item+','
				});
				menu=menu.slice(0,-1)
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'sub_user_role_add',
						name:this.sub_user_role_add.name,
						menu
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:"success",
							str:'添加成功'
						});
						this.sub_user_role_add.is_show = false
						this.get_page_data()
					}
				})
			},
			//打开用户添加页面
			open_add_view(){
				this.role_add()
				this.sub_user_role_add.is_show = true
			},
			role_add(){
				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'sub_menu_list',
					},
					callback:(data)=>{
						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							menu_buf[item.id]=item;

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}
						//格式化子菜单
						for(var i in menu_main){
							menu_main[i].children=menu_children[menu_main[i].id]
						}
						//置入
					
						this.menu_list=menu_main
						this.menu_list_loaded=true
					}
				})
			},
			//登陆到主账户
			login_to_main_user(main_user){
				
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'sub_user_login_to_main_user',
						sub_user_id:main_user.id,
					},
					callback:(data)=>{

						//缓存主公司数据
						this.save_main_user_info(data);
						
						//登陆成功,跳转到主账号系统
						this.$router.push('/pages/sub_user/main_user_sys');
					}
				});
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					main_user_tel:'',//主用户手机
					main_user_name:'',//主用户姓名
					main_user_company_name:'',//主账号公司名称
				}
				this.get_page_data()
			},
			
			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'app_user',
						ctr:'sub_user_role_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false


						//渲染
						this.list.data=data.msg.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 18%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}
</style>